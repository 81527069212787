<template>
    <div class="content">
        <div class="header">
            <div class="title">{{ $t('meeting_session.custom_topic') }}</div>
            <slot name="header_menu_right"></slot>
        </div>
        <div class="body">
            <div class="container-form topic">
                <div class="title-question">
                    <div class="title">{{ data.name }}</div>
                </div>
                <slot name="form_submit"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        optionsUsers: Array,
        data: Object|Boolean
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        changeStepFunction(direction, stepNo = false) {
            if(direction) { 
                this.$emit(direction);
            } else if(stepNo) {
                this.$emit('goToStep', stepNo)
            }
        },
        changeStepOrRedirect(changeStep, withRedirect, userSlug) {
            if(changeStep) {
                this.$nextTick(() => {
                    if(!changeStep.step) {
                        this.$emit(changeStep.direction);
                    } else {
                        this.$emit('goToStep', changeStep.step)
                    }
                });
            }

            if(withRedirect) {
                if(this.$route.query.department_id){
                    this.$router.push({name: 'company'})
                }else{
                    this.$router.push({name: 'user-show', params: { slug: userSlug }})
                }
            }
        },
    }
}
</script>
